const works = [
  {
    id: 4,
    title: 'Comfy Sloth - Frontend App',
    category: 'Frontend',
    video: 'wcJH2HTzKBA',
    img: './img/works/frontend/ezgif-4-c681ecd364.gif',
    desc: 'E-commerce application built using ReactJS and AWS. It provides secure user authentication (Auth0), product viewing, and secure payments through Stripe. It uses AWS services such as Lambda and S3 for serverless backend architecture, and a CI/CD pipeline for seamless deployments',
    githubLik: 'https://github.com/Richardbmk/comfysloth',
    liveDemo: 'https://d1zhh0zrxyj2ke.cloudfront.net/',
  },
  {
    id: 3,
    title: 'AWS EKS Architecture Architectures',
    category: 'Solutions',
    video: 'wqULqmldwNg',
    img: './img/works/solutions/aws-eks-solutions/EKS Architecture 03.jpg',
    desc: 'I have designed multiple AWS architecture diagrams that showcase different solutions using Amazon EKS. These diagrams demonstrate how different AWS services can be leveraged to build a scalable, highly available, and secure Kubernetes infrastructure.',
    githubLik:
      'https://github.com/Richardbmk/aws-architecture-solutions/tree/master/aws-eks-Solutions',
    liveDemo: false,
  },
  {
    id: 2,
    title: 'AWS 3 Tier Architecture',
    category: 'Solutions',
    video: 'CUOrxSUSW5s',
    img: './img/works/solutions/aws-3-tier-solutions/aws-vpc-ec2-alb-rds.jpg',
    desc: 'I designed an AWS architecture for a three-tier application, utilizing EC2, RDS, VPC, ALB, CLB, NLB, ASG, Route53, ACM, CW, SNS, CodePipeline and more.',
    githubLik:
      'https://github.com/Richardbmk/aws-architecture-solutions/tree/master/aws-3-tier-Solutions',
    liveDemo: false,
  },
  {
    id: 1,
    title: 'AWS ECS Fargate Architecture Solutions',
    category: 'Solutions',
    video: 'gKa2F4zyrzg',
    img: './img/works/solutions/ecs-fargate-solutions/microservice-ecs01.jpg',
    desc: 'I designed and implemented an AWS architecture for a microservice application using ECR, App Mesh, ALB, CodePipeline, CodeBuild, CodeCommit, and CloudMap.',
    githubLik:
      'https://github.com/Richardbmk/aws-architecture-solutions/tree/master/aws-fargate-ecs-Solutions',
    liveDemo: false,
  },
];

export default works;
