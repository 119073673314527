import React from 'react';
import HeadHunters from '../../img/undraw_Online_learning_re_qw08.svg';
import './About.css';

const About = () => {
  return (
    <section className='about section' id='about'>
      <h2 className='section-title'>About</h2>

      <div className='about__container bd-grid'>
        <div className='about__img'>
          <img src={HeadHunters} alt='' />
        </div>

        <div className='about__subtitle'>
          <h2>Greetings! My name is Ricardo</h2>
          <p className='about__text'>
            I am a skilled professional with a passion for staying ahead of the
            curve. I hold a degree in Energy Engineer, but my interest in
            emerging technologies and my eagerness to take on new challenges
            have led me to explore the world of Industry 4.0.
          </p>
          <p className='about__text'>
            I have honed my skills in areas such as Cloud Computing,
            programming, automation, and agile methodologies. I have a deep
            understanding of the tools and techniques required to develop, test
            and deploy software efficiently and securely.
          </p>
          <p className='about__text'>
            I firmly believe that coding is a set of powerful tools that enable
            me to bring my clients' ideas to life. By leveraging my professional
            expertise, I help my clients stay ahead of the competition by
            delivering high-quality software products that are both reliable and
            scalable.
          </p>
          <p>
            <b>
              If you're looking for a professional who is passionate about what
              they do and committed to delivering results, then look no further.
              Get in touch with me today to discuss how I can help take your
              business to the next level.
            </b>
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
