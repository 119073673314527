import React from 'react';
import BugFix from '../../img/undraw_bug_fixing_oc7a.svg';

const Error = () => {
  return (
    <section className='home bd-grid' id='home'>
      <div className='home__data'>
        <h1 class='home__title'>
          Something,
          <br />
          went wrong...
          <br />
          <span class='home__title-color'></span>
        </h1>
        <p>I will try to fix it as soon as possible...</p>
        <p>While you wait get to know me more by reading my blog</p>
        <br />
        <a href='https://blog.iotcloudsolutions.com/' class='button'>
          Blog
        </a>
        <a href='/' class='button'>
          Home Page
        </a>
      </div>

      <div className='home__social'>
        <a
          href='https://www.linkedin.com/in/ricardoboriba/'
          class='home__social-icon'
        >
          <i class='bx bxl-linkedin'></i>
        </a>

        <a
          href='https://www.instagram.com/thecloudcomputingway/'
          class='home__social-icon'
        >
          <i class='bx bxl-instagram-alt'></i>
        </a>
        <a href='https://github.com/Richardbmk' class='home__social-icon'>
          <i class='bx bxl-github'></i>
        </a>
        <a href='mailto:rdo.b.mk@gmail.com' class='home__social-icon'>
          <i class='bx bx-mail-send'></i>
        </a>

        <a href='https://blog.iotcloudsolutions.com/' class='home__social-icon'>
          <i class='bx bxl-wordpress'></i>
        </a>
      </div>

      <div class='home__img'>
        <img src={BugFix} alt='' width='100%' />
      </div>
    </section>
  );
};

export default Error;
