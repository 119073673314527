import React, { useState, useEffect } from 'react';
import richCloud from '../../img/undraw_web_developer_p3e5.svg';
import richCV from '../../docs/rboriba-new-resume_v1.pdf';
import './Home.css';

const Home = () => {
  const [careerNames, setCareerName] = useState([
    'Cloud Engineer',
    'DevOps Engineer',
    'K8s Engineer',
    'Serverless Engineer',
    'Cloud Architect',
    'Cloud Developer',
    'Software Developer',
    'K8s Architect',
  ]);

  const [currentCareerNameIndex, setCurrentCareerNameIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentCareerNameIndex(Math.floor(Math.random() * careerNames.length));
    }, 10000);

    return () => clearInterval(intervalId);
  }, [currentCareerNameIndex, careerNames]);

  return (
    <section className='home bd-grid' id='home'>
      <div className='home__data'>
        <h1 className='home__title'>
          Hi,
          <br />I am <span className='home__title-color'>Ricardo</span>
          <br />
          {careerNames[currentCareerNameIndex]}
        </h1>

        <a href={richCV} className='button'>
          Download CV
        </a>

        <a
          href='https://www.credly.com/users/ricardo-boriba-makuere'
          className='button'
        >
          Credly Badges
        </a>
      </div>

      <div className='home__social'>
        <a
          href='https://www.linkedin.com/in/ricardoboriba/'
          className='home__social-icon'
        >
          <i className='bx bxl-linkedin'></i>
        </a>
        <a href='https://github.com/Richardbmk' className='home__social-icon'>
          <i className='bx bxl-github'></i>
        </a>
        <a href='#contact' className='home__social-icon'>
          <i className='bx bx-mail-send'></i>
        </a>
        <a href='https://medium.com/@ricardobmk' className='home__social-icon'>
          <i className='bx bxl-medium-square'></i>
        </a>
        <div className='home__img'>
          <img src={richCloud} alt='' />
        </div>
      </div>
    </section>
  );
};

export default Home;
