import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage/HomePage';
import SendSuccess from './pages/SendSuccess/SendSuccess';
import GeneralError from './pages/GeneralError/GeneralError';
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/send-success' element={<SendSuccess />} />
        <Route path='*' element={<GeneralError />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
