import React, { useState } from 'react';
import Works from '../Works/Works';
import Categories from '../Categories/Categories';
import items from '../../works';
import './Work.css';

const allCategories = ['all', ...new Set(items.map((item) => item.category))];

const Work = () => {
  const [worksItems, setWorksItem] = useState(items);
  const [categories, setCategories] = useState(allCategories);

  // console.log(allCategories);
  // console.log(worksItems);

  const filterItems = (category) => {
    if (category === 'all') {
      setWorksItem(items);
      return;
    }
    const newItems = items.filter((item) => item.category === category);
    setWorksItem(newItems);
  };

  return (
    <section className='work section menu' id='work'>
      <h2 className='section-title'>My Projects</h2>
      <Categories categories={categories} filterItems={filterItems} />
      <div className='work__container bd-grid section-center'>
        {worksItems.map((item) => (
          <Works
            key={item.id}
            title={item.title}
            img={item.img}
            desc={item.desc}
            video={item.video}
            githubLik={item.githubLik}
            liveDemo={item.liveDemo}
          />
        ))}
      </div>
    </section>
  );
};

export default Work;
