import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Home from '../../components/Home/Home';
import About from '../../components/About/About';
import Work from '../../components/Work/Work';
import Skills from '../../components/Skills/Skills';
import Contact from '../../components/Contact/Contact';
import Footer from '../../components/Footer/Footer';

const HomePage = () => {
  return (
    <div>
      <div className='App'>
        <Navbar />
        <Home />
        <About />
        <Skills />
        <Work />
        <Contact />
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
