import React, { useState } from 'react';
import Modal from 'react-modal';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';
import './Works.css';

const Works = ({ id, title, img, desc, video, githubLik, liveDemo }) => {
  const [isOpen, setOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleImageClick = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    console.log('Closing modal!!');
  };

  return (
    <div key={id} className='work__img menu-item'>
      <img onClick={handleImageClick} src={img} alt={title} className='photo' />
      <div className='card_info item-info'>
        <h4>{title}</h4>
        <p className='item-text'>{desc}</p>
        <div className=''>
          <a
            href={githubLik}
            target='_blank'
            rel='noreferrer'
            className='home__social-icon'
            style={{ marginRight: 0 }}
          >
            <i className='bx bxl-github' style={{ fontSize: '1.5em' }}></i>
          </a>
          <a onClick={() => setOpen(true)} className='home__social-icon'>
            <i className='bx bxl-youtube' style={{ fontSize: '1.5em' }}></i>
          </a>
          <ModalVideo
            wmode='transparent'
            // autoplay
            theme='dark'
            channel='youtube'
            isOpen={isOpen}
            videoId={video}
            onClose={() => setOpen(false)}
          />
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            ariaHideApp={false}
          >
            <div className='modal-content'>
              <img src={img} alt={title} />
            </div>
          </Modal>
        </div>
        {liveDemo ? (
          <a className='button_work' href={liveDemo} target='_blank'>
            Live Demo
          </a>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default Works;
