import { useNavigate } from 'react-router';
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './Contact.css';

const Contact = () => {
  const navigate = useNavigate();
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_tvybvfq',
        'template_2bx224h',
        form.current,
        'f_AzqE3uZPwdS-Vdd'
      )
      .then(
        (result) => {
          navigate('/send-success');
        },
        (error) => {
          navigate('*');
        }
      );
  };

  return (
    <section className='contact section' id='contact'>
      <h2 className='section-title'>Contact</h2>

      <div className='contact__container bd-grid'>
        {/* Google embeded map */}
        <iframe
          style={{
            width: '520px',
            height: '400px',
            marginBottom: '2rem',
            marginheight: '0',
            marginWidth: '0',
            frameBorder: '0',
            display: 'block',
            width: '100%',
          }}
          allowfullscreen=''
          loading='lazy'
          id='gmap_canvas'
          title='myFrame'
          src='https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Landesgerichtsstra%C3%9Fe%20Vienna+()&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed'
        ></iframe>
        {/* Contact form  */}
        <form
          ref={form}
          onSubmit={sendEmail}
          id='form_contact'
          method='post'
          className='contact__form'
        >
          <input
            type='text'
            name='from_name'
            placeholder='Name'
            className='contact__input'
            required
          />
          <input
            maxLength='64'
            type='email'
            name='user_email'
            placeholder='Email'
            className='contact__input'
            required
          />
          <textarea
            name='message'
            id=''
            cols='0'
            rows='10'
            placeholder='How can I help?'
            className='contact__input'
            required
          ></textarea>
          <input
            id='send-success'
            type='submit'
            name='Button'
            value='Send'
            className='contact__button button'
          />
          <div id='output'></div>
        </form>
      </div>
    </section>
  );
};

export default Contact;
