import React from 'react';
import Error from '../../components/Error/Error';

const GeneralError = () => {
  return (
    <div>
      <div className='App'>
        <Error />
      </div>
    </div>
  );
};

export default GeneralError;
