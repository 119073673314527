import React from 'react';
import './Footer.css';

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className='footer'>
      <p className='footer__title'></p>
      <div className='footer__social'>
        <a
          href='https://www.linkedin.com/in/ricardoboriba/'
          className='footer__icon'
        >
          <i className='bx bxl-linkedin'></i>
        </a>
        <a href='https://github.com/Richardbmk' className='footer__icon'>
          <i className='bx bxl-github'></i>
        </a>
        <a href='mailto:rdo.b.mk@gmail.com' className='footer__icon'>
          <i className='bx bx-mail-send'></i>
        </a>
        <a href='https://medium.com/@ricardobmk' className='footer__icon'>
          <i className='bx bxl-medium-square'></i>
        </a>
      </div>
      <p>
        &#169; {year} copyright all right reserved. Credits for the tamplete to{' '}
        <a
          href='https://github.com/bedimcode/portfolio-responsive-complete'
          style={{ color: 'var(--first-color)' }}
        >
          Bedimcode
        </a>
      </p>
    </footer>
  );
};

export default Footer;
