import React from 'react';
import Python from '../../img/python.png';
import JavaScript from '../../img/javascript.png';
import Nodejs from '../../img/nodejs.png';
import Mongodb from '../../img/mongodb.png';
import Mysql from '../../img/mysql.png';
// import Bash from '../../img/bash.png';
import Bash from '../../img/ezgif-1-74f07cde91.gif';
import AWS from '../../img/aws.png';
import Docker from '../../img/docker.png';
import Git from '../../img/git.png';
import Linux from '../../img/linux.jpg';
import Terraform from '../../img/terraform.png';
// import Kubernetes from '../../img/kubernetes.png';
import Kubernetes from '../../img/ezgif-2-f1e9bc4d4c.gif';
import CloudFormation from '../../img/cloudformation.png';
import CICDTools from '../../img/cicd-tools.gif';
import RandomK8sTools from '../../img/ezgif-1-8f0031bf9d.gif';
// import TheCoder from '../../img/final_5fdcadde5884e00029c68490_434210.gif';
import TheCoder from '../../img/ezgif-5-7f4b2c4569.gif';
import './Skills.css';

const Skills = () => {
  return (
    <section className='skills section' id='skills'>
      <h2 className='section-title'>Skills & Tools</h2>

      <div className='skills__container bd-grid'>
        <div>
          <h2 className='skills__subtitle'>Professional background</h2>
          <p className='skills__text'>
            My experience has given me a wide range of skills and expertise in
            various tools and programming languages. Here are just a few
            examples of tools and technologies that I am comfortable using:
          </p>
          <div className='skills__data'>
            <img
              src={JavaScript}
              alt='javascript'
              className='skills__img'
              width='45'
              height='45'
            />
            <img
              src={Python}
              alt='javascript'
              className='skills__img'
              width='45'
              height='45'
            />
            <img
              src={Nodejs}
              alt='javascript'
              className='skills__img'
              width='45'
              height='45'
            />
            <img
              src={Mongodb}
              alt='javascript'
              className='skills__img'
              width='45'
              height='45'
            />
            <img
              src={Mysql}
              alt='javascript'
              className='skills__img'
              width='45'
              height='45'
            />
          </div>
          <div className='skills__data'>
            <img
              src={Terraform}
              alt='terraform'
              className='skills__img'
              width='45'
              height='45'
            />
            <img
              src={Git}
              alt='git'
              className='skills__img'
              width='45'
              height='45'
            />
            <img
              src={Linux}
              alt='linux'
              className='skills__img'
              width='45'
              height='45'
            />
            <img
              src={Kubernetes}
              alt='kubernetes'
              className='skills__img'
              width='45'
              height='45'
            />
            <img
              src={AWS}
              alt='aws'
              className='skills__img'
              width='45'
              height='45'
            />
          </div>
          <div className='skills__data'>
            <img
              src={Bash}
              alt='aws'
              className='skills__img'
              width='45'
              height='45'
            />
            <img
              src={Docker}
              alt='javascript'
              className='skills__img'
              width='45'
              height='45'
            />
            <img
              src={CloudFormation}
              alt='javascript'
              className='skills__img'
              width='45'
              height='45'
            />
            <img
              src={RandomK8sTools}
              alt='javascript'
              className='skills__img'
              width='45'
              height='45'
            />
            <img
              src={CICDTools}
              alt='javascript'
              className='skills__img'
              width='45'
              height='45'
            />
          </div>
          <p>
            If you have a specific tool or technology in mind that you'd like me
            to use, I'm always eager to learn new skills and incorporate them
            into my work.
          </p>
        </div>
        <div>
          <img
            src={TheCoder}
            alt='someone-coding-fast'
            className='skills__big_img'
          />
        </div>
      </div>
    </section>
  );
};

export default Skills;
