import React from 'react';
import './Navbar.css';
import { useGlobalContext } from '../../context';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const {
    isSidebarOpen,
    toggleSidebar,
    hideSidebar,
    markActiveElement,
    activeElement,
  } = useGlobalContext();

  return (
    <header className='l-header'>
      <nav className='nav bd-grid'>
        {/* LeftNavbar */}
        <div>
          <a href='#' className='nav__logo'>
            Ricardo B. Makuere
          </a>
        </div>

        {/* RightNavbar */}
        <div
          className={`${isSidebarOpen ? 'nav__menu show' : 'nav__menu'}`}
          id='nav-menu'
        >
          <ul className='nav__list'>
            <li className='nav__item' onClick={hideSidebar}>
              <a
                href='#home'
                className={`nav__link ${
                  activeElement === 'home' ? 'active' : ''
                }`}
                onClick={() => markActiveElement('home')}
              >
                Home
              </a>
            </li>
            <li className='nav__item' onClick={hideSidebar}>
              <a
                href='#about'
                className={`nav__link ${
                  activeElement === 'about' ? 'active' : ''
                }`}
                onClick={() => markActiveElement('about')}
              >
                About
              </a>
            </li>
            <li className='nav__item' onClick={hideSidebar}>
              <a
                href='#skills'
                className={`nav__link ${
                  activeElement === 'skills' ? 'active' : ''
                }`}
                onClick={() => markActiveElement('skills')}
              >
                Skills
              </a>
            </li>
            <li className='nav__item' onClick={hideSidebar}>
              <a
                href='#work'
                className={`nav__link ${
                  activeElement === 'work' ? 'active' : ''
                }`}
                onClick={() => markActiveElement('work')}
              >
                Work
              </a>
            </li>
            <li className='nav__item'>
              <a href='https://medium.com/@ricardobmk' className='nav__link'>
                Blog
              </a>
            </li>
            <li className='nav__item' onClick={hideSidebar}>
              <a
                href='#contact'
                className={`nav__link ${
                  activeElement === 'contact' ? 'active' : ''
                }`}
                onClick={() => markActiveElement('contact')}
              >
                Contact
              </a>
            </li>
            <li className='nav__item'>
              <a
                href='https://mentorcruise.com/mentor/ricardoboribamakuere/'
                className='nav__link'
              >
                Mentoring
              </a>
            </li>
            <li className='nav__item'>
              <a
                href='https://www.upwork.com/freelancers/~01e1fef4b462d08495?viewMode=1'
                className='nav__link'
              >
                Upwork
              </a>
            </li>
          </ul>
        </div>

        <div className='nav__toggle' id='nav-toggle' onClick={toggleSidebar}>
          <i className='bx bx-menu'></i>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
